import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { validate } from 'uuid'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

import {
  selectWebAppStateCompanyUserId,
  selectWebAppStateMySelf,
  selectWebAppStateMySelfHasAnalyticsAccess,
} from '../../store/getters/web-app.getters'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import { PersonInterface } from '../../core/enums/person.interface'
import { PermissionEnum } from '../../core/enums/permission.enum'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import {
  analyticDetailsV2StateDeleteUserToShareReport,
  analyticDetailsV2StateNewConstellationGetBookRecoV3Action,
  analyticDetailsV2StatePatchReportByIdChangeOwnerAction,
  analyticDetailsV2StatePatchUserPermissionToAnalyticAction,
  analyticDetailsV2StatePostNewAnalyticsAction,
  analyticDetailsV2StatePutAnalyticDetailsByIdAction,
  analyticDetailsV2StatePutPermissionByReportIdAndUserId,
  analyticsDetailV2StateGetMockReadershipProfile,
  analyticsDetailV2StateGetReportByIdAction,
  analyticsDetailV2StateNewAnalyticAction,
} from '../../store/actions/analytics-details/analytic-details-v2.actions'
import AnalyticDetailsLayout from '../../components/analytics-details-v2/AnalyticDetailsLayout'
import {
  selectAnalyticDetailsStateIsLoadingGetAnalyticDetails,
  selectAnalyticDetailsStateAnalyticDetails,
  selectAnalyticDetailsStateAnalyticDetailsBooksInput,
  selectAnalyticDetailsStateAnalyticDetailsBooksOutput,
  selectAnalyticDetailsStateAnalyticDetailsType,
  selectAnalyticDetailsStatePostNewAnalyticSuccess,
  selectAnalyticDetailsStatePostNewAnalyticError,
  selectAnalyticDetailsStatePutAnalyticSuccess,
  selectAnalyticDetailsStatePutAnalyticError,
  selectAnalyticDetailsStateIsLoadingPostNewAnalytic,
  selectAnalyticDetailsStateNeedSaveAnalyticDetails,
  selectAnalyticDetailsStateIsLoadingPatchUserPermissionToAnalytic,
  selectAnalyticDetailsStatePatchUserPermissionToAnalyticSuccess,
  selectAnalyticDetailsStatePatchUserPermissionToAnalyticError,
  selectAnalyticDetailsStateIsLoadingPutUserPermissionToAnalytic,
  selectAnalyticDetailsStatePutUserPermissionToAnalyticSuccess,
  selectAnalyticDetailsStatePutUserPermissionToAnalyticError,
  selectAnalyticDetailsStateIsLoadingDeleteUserToShareReport,
  selectAnalyticDetailsStateDeleteUserToShareReportSuccess,
  selectAnalyticDetailsStateDeleteUserToShareReportError,
  selectAnalyticDetailsStateIsLoadingPatchReportByIdChangeOwner,
  selectAnalyticDetailsStatePatchReportByIdChangeOwnerSuccess,
  selectAnalyticDetailsStatePatchReportByIdChangeOwnerError,
  selectAnalyticDetailsStateIsLoadingPutAnalytic,
} from '../../store/getters/analytics-details/analytic-details-v2.getters'
import {
  analyticsDetailV2StateSetAnalyticDetailsName,
  reinitializeAnalyticDetailsV2State,
  resetDeleteUserToShareReportStatus,
  resetPatchReportByIdChangeOwnerStatus,
  resetPostNewAnalyticStatus,
  resetPutAnalyticStatus,
  resetPutUserPermissionToAnalyticStatus,
} from '../../store/reducers/analytics-details/analytic-details-v2.reducer'
import { AnalyticsTypeEnum } from '../../core/enums/analytics-type.enum'
import SwitchViewAnalytic from '../../components/analytics-details-v2/view/SwitchViewAnalytic'
import GlDialog from '../../components/share/dialog/GlDialog'
import GlSpinner from '../../components/share/GlSpinner'
import GlMatInputOutline from '../../components/share/inputs/GlMatInputOutline'
import {
  selectNewAnalyticsReadershipProfileStatePostPreviewError,
  selectNewAnalyticsReadershipProfileStatePostPreviewTypeError,
} from '../../store/getters/analytics-details/new-analytics-readership-profile.getters'
import { AnalyticPreviewTypeErrorEnum } from '../../core/enums/analytic-preview-type-error'
import BackGroundPreviewTypeError from '../../assets/images/preview-report-type-error.svg'
import AnalyticsDashboardShareDialog from '../../components/analytics-dashboard/more-actions/share-analytics/AnalyticsDashboardShareDialog'
import { newReadershipProfileStateGotToStartStep } from '../../store/reducers/analytics-details/new-analytics-readership-profile-state.reducer'

const DialogTitle = styled.div`
  font-family: Vollkorn, serif;
  font-style: normal;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
`

const DialogBody = styled.div`
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  color: #252525;
`

const Button = styled.button`
  // border: 1px solid #252525;
  color: ${({ primary = false }: { primary?: boolean }) =>
    (primary && 'white') || '#252525'};
  background-color: ${({ primary = false }: { primary?: boolean }) =>
    (primary && '#252525') || '#E9E9E9'};
  opacity: ${({
    primary = false,
    isLoading,
  }: {
    primary?: boolean
    isLoading?: boolean
  }) => (isLoading && 0.6) || (primary && 1) || 0.7};
  border-radius: 4px;
  padding: 8px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`

const AnalyticDetailsView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [renameAnalyticDialog, setRenameAnalyticDialog] =
    useState<boolean>(false)
  const [hasNavigatePrevious, setHasNavigatePrevious] = useState<boolean>(false)
  const [firstEanSearch, setFirstEanSearch] = useState<string | null>('')
  const [shareDialog, setShareDialog] = useState<boolean>(false)
  const [saveBeforeExitDialog, setSaveBeforeExitDialog] =
    useState<boolean>(false)
  const [displayPreviewErrorDialog, setDisplayPreviewErrorDialog] =
    useState<boolean>(true)

  const { reportId: urlReportId } = useParams<{ reportId: string }>()
  const [reportId, setReportId] = useState<string>(urlReportId as string)
  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()

  const analyticDetailsType = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetailsType,
  )
  const isLoadingGetAnalyticDetails = useAppSelector(
    selectAnalyticDetailsStateIsLoadingGetAnalyticDetails,
  )

  const companyUserId = useAppSelector(selectWebAppStateCompanyUserId)

  const isLoadingPostNewAnalytic = useAppSelector(
    selectAnalyticDetailsStateIsLoadingPostNewAnalytic,
  )

  const isLoadingPutAnalytic = useAppSelector(
    selectAnalyticDetailsStateIsLoadingPutAnalytic,
  )
  const analyticDetails = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetails,
  )

  const mySelf = useAppSelector(selectWebAppStateMySelf)
  const hasAnalyticsAccess = useAppSelector(
    selectWebAppStateMySelfHasAnalyticsAccess,
  )
  useEffect(() => {
    if (mySelf && !hasAnalyticsAccess) {
      dispatch(setShowSnackBar(t('webApp.notAccessToFeature')))
      navigate(buildRelativePath([PathConfig.BOOKMETRIE]))
    }
  }, [mySelf, hasAnalyticsAccess])

  const booksInput =
    useAppSelector(selectAnalyticDetailsStateAnalyticDetailsBooksInput) || []
  const booksOutput = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetailsBooksOutput,
  )

  const onClickEditSearchButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    setDisplayPreviewErrorDialog(false)
    dispatch(newReadershipProfileStateGotToStartStep())
  }

  function manageNavigatePrevious() {
    if (!firstEanSearch) {
      if (reportId === 'new') {
        setFirstEanSearch(searchParams.get('eans'))
      } else if (analyticDetails?.inputs[0]?.values) {
        setFirstEanSearch(analyticDetails.inputs[0].values[0])
      }
    } else if (firstEanSearch !== searchParams.get('eans')) {
      setHasNavigatePrevious(!!searchParams.get('eans'))
    } else {
      setHasNavigatePrevious(false)
    }

    if (!searchParams.get('eans')) {
      setFirstEanSearch(null)
    }
  }

  useEffect(() => {
    setReportId(urlReportId as string)
  }, [urlReportId])

  // Au chargement de la page
  useEffect(() => {
    if (hasAnalyticsAccess) {
      if (reportId === 'new' && searchParams.get('type')) {
        const analyticType = searchParams.get('type') as AnalyticsTypeEnum
        dispatch(analyticsDetailV2StateNewAnalyticAction({ analyticType }))
          .then(() => {
            if (searchParams.get('eans')) {
              const eanIds = `${searchParams.get('eans')}`
                .split(',')
                .map((ean: string) => Number(ean))
              void dispatch(
                analyticDetailsV2StateNewConstellationGetBookRecoV3Action({
                  eanIds,
                }),
              )
            }
          })
          .catch(() => {})
          .finally(() => {})
      }
      if (
        validate(reportId) &&
        !searchParams.get('type') &&
        !searchParams.get('eans')
      ) {
        void dispatch(analyticsDetailV2StateGetReportByIdAction({ reportId }))
      }

      if (reportId === 'readership_profile') {
        void dispatch(analyticsDetailV2StateGetMockReadershipProfile())
      }

      manageNavigatePrevious()
    }

    return () => {
      dispatch(reinitializeAnalyticDetailsV2State())
    }
  }, [reportId, searchParams.get('type'), searchParams.get('eans')])

  const postAnalytic = (toExit: boolean) => {
    if (booksInput.length && booksOutput.length) {
      dispatch(analyticDetailsV2StatePostNewAnalyticsAction())
        .then(() => {
          if (toExit) {
            navigate(
              buildRelativePath([PathConfig.BOOKMETRIE, PathConfig.ANALYTICS]),
            )
          }
          setSaveBeforeExitDialog(false)
          setRenameAnalyticDialog(false)
        })
        .finally(() => dispatch(resetPostNewAnalyticStatus()))
    }
  }

  const putAnalytic = (toExit: boolean) => {
    dispatch(analyticDetailsV2StatePutAnalyticDetailsByIdAction())
      .unwrap()
      .then(() => {
        if (toExit) {
          navigate(
            buildRelativePath([PathConfig.BOOKMETRIE, PathConfig.ANALYTICS]),
          )
        }
        setSaveBeforeExitDialog(false)
        setRenameAnalyticDialog(false)
      })
      .finally(() => dispatch(resetPutAnalyticStatus()))
  }

  const postAnalyticSuccess = useAppSelector(
    selectAnalyticDetailsStatePostNewAnalyticSuccess,
  )
  const postAnalyticError = useAppSelector(
    selectAnalyticDetailsStatePostNewAnalyticError,
  )
  const putAnalyticSuccess = useAppSelector(
    selectAnalyticDetailsStatePutAnalyticSuccess,
  )
  const putAnalyticError = useAppSelector(
    selectAnalyticDetailsStatePutAnalyticError,
  )

  const needSaveAnalyticDetails = useAppSelector(
    selectAnalyticDetailsStateNeedSaveAnalyticDetails,
  )

  const postPreviewError = useAppSelector(
    selectNewAnalyticsReadershipProfileStatePostPreviewError,
  )
  const postPreviewTypeError = useAppSelector(
    selectNewAnalyticsReadershipProfileStatePostPreviewTypeError,
  )

  useEffect(() => {
    if (postPreviewError && !displayPreviewErrorDialog) {
      setDisplayPreviewErrorDialog(true)
    }
  }, [postPreviewError])

  useEffect(() => {
    if ((putAnalyticSuccess || postAnalyticSuccess) && analyticDetails?.id) {
      void dispatch(
        setShowSnackBar(
          t('snackbarMessage.saveReportConfirm', {
            reportName: analyticDetails?.name || '',
          }),
        ),
      )
    }
    if (postAnalyticSuccess && analyticDetails?.id) {
      navigate(
        buildRelativePath([
          PathConfig.BOOKMETRIE,
          PathConfig.ANALYTICS,
          analyticDetails?.id,
        ]),
      )
    }

    if (putAnalyticError || postAnalyticError) {
      dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
    }
  }, [
    analyticDetails,
    postAnalyticSuccess,
    postAnalyticError,
    putAnalyticError,
    putAnalyticSuccess,
    reportId,
  ])

  const handleChangeAnalyticName = (name: string) => {
    dispatch(analyticsDetailV2StateSetAnalyticDetailsName(name))
  }

  const handleSaveAnalytics = () => {
    setRenameAnalyticDialog(true)
  }
  const handleSaveAnalyticMain = (toExit = false) => {
    if (reportId === 'new') {
      void postAnalytic(toExit)
    } else {
      void putAnalytic(toExit)
    }
  }

  const isLoadingPatchUserPermissionToAnalytic = useAppSelector(
    selectAnalyticDetailsStateIsLoadingPatchUserPermissionToAnalytic,
  )
  const patchUserPermissionToAnalyticSuccess = useAppSelector(
    selectAnalyticDetailsStatePatchUserPermissionToAnalyticSuccess,
  )
  const patchUserPermissionToAnalyticError = useAppSelector(
    selectAnalyticDetailsStatePatchUserPermissionToAnalyticError,
  )
  const addUsersTeamsToShareReport = (
    users: PersonInterface[],
    permission: PermissionEnum,
    message: string,
  ) => {
    void dispatch(
      analyticDetailsV2StatePatchUserPermissionToAnalyticAction({
        reportId: analyticDetails?.id,
        newData: {
          users: users.map((user: PersonInterface) => user.id),
          permission,
          message,
        },
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(setShowSnackBar(t('webApp.reports.confirmAddShareUser')))
        setShareDialog(false)
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      })
  }

  const isLoadingPutUserPermissionToAnalytic = useAppSelector(
    selectAnalyticDetailsStateIsLoadingPutUserPermissionToAnalytic,
  )
  const putUserPermissionToAnalyticSuccess = useAppSelector(
    selectAnalyticDetailsStatePutUserPermissionToAnalyticSuccess,
  )
  const putUserPermissionToAnalyticError = useAppSelector(
    selectAnalyticDetailsStatePutUserPermissionToAnalyticError,
  )
  const updatePermissionToUser = (
    user: PersonInterface,
    permission: PermissionEnum,
  ) => {
    void dispatch(
      analyticDetailsV2StatePutPermissionByReportIdAndUserId({
        reportId: analyticDetails?.id,
        userId: user.id,
        permission,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(setShowSnackBar(t('webApp.reports.confirmChangeRightUser')))
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      })
      .finally(() => {
        dispatch(resetPutUserPermissionToAnalyticStatus())
      })
  }

  const isLoadingDeleteUserToShareReport = useAppSelector(
    selectAnalyticDetailsStateIsLoadingDeleteUserToShareReport,
  )
  const deleteUserToShareReportSuccess = useAppSelector(
    selectAnalyticDetailsStateDeleteUserToShareReportSuccess,
  )
  const deleteUserToShareReportError = useAppSelector(
    selectAnalyticDetailsStateDeleteUserToShareReportError,
  )
  const deleteUserToShareReport = (user: PersonInterface) => {
    void dispatch(
      analyticDetailsV2StateDeleteUserToShareReport({
        reportId: analyticDetails?.id,
        userId: user.id,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(setShowSnackBar(t('webApp.reports.confirmDeleteUser')))
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      })
      .finally(() => {
        dispatch(resetDeleteUserToShareReportStatus())
      })
  }

  const isLoadingPatchReportByIdChangeOwner = useAppSelector(
    selectAnalyticDetailsStateIsLoadingPatchReportByIdChangeOwner,
  )
  const patchReportByIdChangeOwnerSuccess = useAppSelector(
    selectAnalyticDetailsStatePatchReportByIdChangeOwnerSuccess,
  )
  const patchReportByIdChangeOwnerError = useAppSelector(
    selectAnalyticDetailsStatePatchReportByIdChangeOwnerError,
  )

  const handleChangeOwner = ({
    reportId,
    ownerId,
  }: {
    reportId: string
    ownerId: string
  }) => {
    void dispatch(
      analyticDetailsV2StatePatchReportByIdChangeOwnerAction({
        reportId,
        ownerId,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(setShowSnackBar(t('webApp.reports.confirmChangeOwner')))
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      })
      .finally(() => {
        dispatch(resetPatchReportByIdChangeOwnerStatus())
      })
  }

  const handleReloadAnalyticDetails = () => {
    if (validate(reportId)) {
      void dispatch(analyticsDetailV2StateGetReportByIdAction({ reportId }))
    }
  }

  return (
    <>
      <AnalyticDetailsLayout
        hasButtonPrevious={hasNavigatePrevious}
        analyticDetails={analyticDetails}
        isLoading={isLoadingGetAnalyticDetails}
        handleExitAnalyticDetails={() => {
          if (needSaveAnalyticDetails) {
            setSaveBeforeExitDialog(true)
          } else {
            return navigate(
              buildRelativePath([PathConfig.BOOKMETRIE, PathConfig.ANALYTICS]),
            )
          }
        }}
      >
        <SwitchViewAnalytic
          type={analyticDetailsType}
          handleSaveAnalytics={handleSaveAnalytics}
          handleShareAnalytics={() => setShareDialog(true)}
        />
      </AnalyticDetailsLayout>
      {postPreviewError && (
        <GlDialog
          open={displayPreviewErrorDialog}
          onClose={() => {
            setDisplayPreviewErrorDialog(false)
          }}
          backgroundImage={BackGroundPreviewTypeError}
        >
          <div
            className="flex flex-col gap-2 justify-end	"
            style={{
              width: 580,
              height: 312,
            }}
          >
            <DialogTitle className="text-center">
              {postPreviewTypeError ===
                AnalyticPreviewTypeErrorEnum.SUBJECT_TOO_SMALL &&
                t('NotEnoughDataOnSubject')}
              {postPreviewTypeError ===
                AnalyticPreviewTypeErrorEnum.COMPARISON_TOO_SMALL &&
                t('NotEnoughDataOnComparison')}
              {postPreviewTypeError === AnalyticPreviewTypeErrorEnum.ERROR &&
                t('ErrorHasOccurred')}
            </DialogTitle>
            <DialogBody
              style={{
                textAlign: 'center',
                fontSize: '14px',
                color: '#666',
              }}
            >
              <div>
                {postPreviewTypeError ===
                  AnalyticPreviewTypeErrorEnum.SUBJECT_TOO_SMALL &&
                  t('SubjectTooSmall')}
                {postPreviewTypeError ===
                  AnalyticPreviewTypeErrorEnum.COMPARISON_TOO_SMALL &&
                  t('ComparisonTooSmall')}
                {postPreviewTypeError === AnalyticPreviewTypeErrorEnum.ERROR &&
                  t('RequestCouldNotBeCompleted')}
              </div>
              <div>{t('MayChangeSearch')}</div>
            </DialogBody>
            <div className="flex flex-row justify-between items-center gap-4">
              <Button
                type="button"
                className="relative bg-[#252525] flex-1 border-[#252525] mt-6 hover:opacity-60 active:opacity-100"
                primary
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  onClickEditSearchButton(event)
                }}
              >
                <span className="text-[#ffffff] font-medium">
                  {t('EditTheSearch')}
                </span>
              </Button>
            </div>
          </div>
        </GlDialog>
      )}
      {(analyticDetailsType === AnalyticsTypeEnum.READERSHIP_PROFILE && (
        <GlDialog
          open={saveBeforeExitDialog}
          onClose={() => {
            setSaveBeforeExitDialog(false)
          }}
        >
          <div
            className="flex flex-col gap-4"
            style={{
              width: 432,
            }}
          >
            <DialogBody>
              <div>{t('areYouSureToLeave')}</div>
            </DialogBody>
            <div className="flex flex-row justify-between items-center gap-4">
              <Button
                type="button"
                className="hover:bg-[#f7f7f7] active:bg-[#e3e3e3] flex-1 flex flex-row items-center justify-center gap-2"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault()
                  if (saveBeforeExitDialog) {
                    navigate(
                      buildRelativePath([
                        PathConfig.BOOKMETRIE,
                        PathConfig.ANALYTICS,
                      ]),
                    )
                  }
                  setSaveBeforeExitDialog(false)
                }}
              >
                <span>{t('yes')}</span>
              </Button>
              <Button
                type="button"
                className="relative text-[#ffffff] bg-[#252525] flex-1 border-[#252525] hover:opacity-60 active:opacity-100"
                primary
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault()
                  setSaveBeforeExitDialog(false)
                }}
              >
                <span>{t('no')}</span>
              </Button>
            </div>
          </div>
        </GlDialog>
      )) || (
        <GlDialog
          open={renameAnalyticDialog || saveBeforeExitDialog}
          onClose={() => {
            setRenameAnalyticDialog(false)
            setSaveBeforeExitDialog(false)
          }}
        >
          <div
            className="flex flex-col gap-4"
            style={{
              width: 432,
            }}
          >
            <DialogTitle className="pr-4">{t('toSave')}</DialogTitle>
            <DialogBody>
              {(saveBeforeExitDialog && (
                <div>{t('wishSaveSearchBeforeToExit')}</div>
              )) ||
                null}
              <GlMatInputOutline
                type="text"
                onChangeValue={(value: string | number) =>
                  handleChangeAnalyticName(value as string)
                }
                label=""
                value={analyticDetails?.name || ''}
                placeholder={t('analyticNamePlaceHolder')}
                hasClearButton
                hasErrorMessage={t('analyticNameRequired')}
              />
            </DialogBody>
            <div className="flex flex-row justify-between items-center gap-4">
              <Button
                type="button"
                className="hover:bg-[#f7f7f7] active:bg-[#e3e3e3] flex-1 flex flex-row items-center justify-center gap-2"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault()
                  if (saveBeforeExitDialog) {
                    navigate(
                      buildRelativePath([
                        PathConfig.BOOKMETRIE,
                        PathConfig.ANALYTICS,
                      ]),
                    )
                  }
                  setRenameAnalyticDialog(false)
                  setSaveBeforeExitDialog(false)
                }}
              >
                {(saveBeforeExitDialog && (
                  <span>{t('commons.buttons.exit')}</span>
                )) || <span>{t('commons.buttons.cancel')}</span>}
              </Button>
              <Button
                type="button"
                className="relative text-[#ffffff] bg-[#252525] flex-1 border-[#252525] hover:opacity-60 active:opacity-100"
                primary
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault()
                  handleSaveAnalyticMain(saveBeforeExitDialog)
                }}
                isLoading={isLoadingPostNewAnalytic || isLoadingPutAnalytic}
                disabled={!analyticDetails?.name}
              >
                {((isLoadingPostNewAnalytic || isLoadingPutAnalytic) && (
                  <div className="absolute w-full h-full top-0 left-0 justify-center items-center">
                    <GlSpinner />
                  </div>
                )) ||
                  null}
                {t('commons.buttons.save')}
              </Button>
            </div>
          </div>
        </GlDialog>
      )}
      <GlDialog open={shareDialog} onClose={() => setShareDialog(false)}>
        {analyticDetails && (
          <AnalyticsDashboardShareDialog
            analyticsName={analyticDetails?.name}
            analyticsId={analyticDetails?.id}
            owner={analyticDetails?.owner}
            companyUserId={companyUserId}
            slug={analyticDetails?.slug}
            analyticsType={analyticDetailsType}
          />
        )}
      </GlDialog>
    </>
  )
}
export default AnalyticDetailsView
